import styled from 'styled-components';
import { graphql, navigate } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"
import "@babel/polyfill";
import GravityFormForm from 'gatsby-plugin-gravity-forms-ignitro'

import '../css/contact-form.css';
import '../css/landing-page-form.css';

import Layout from "../layouts"
import Head from "../components/head"
import ImageHero from "../components/image-hero"
import FormHero from "../components/formHero"
import CopyLeftBlock from '../components/copyleft';
import CopyRightBlock from "../components/copyright"
import CopyRightBG from "../components/copyright-bg"
import CopyFullBlock from '../components/copyfull';
import ThreeColumn from "../components/three-column";
import Articles from '../components/articles';
import QuoteSlider from '../components/quote-slider';
import Solutions from '../components/solutions';
import FAQAccordions from '../components/faq-accordions';
import CTAImage from '../components/cta-image';
import Contact from "../components/contact"
import Locations from "../components/locations"
import TeamMembersBlock from "../components/team-members"
import TestimonialSlider from '../components/testimonial-slider';
import VideoSlider from '../components/video-slider';
import VideoGallery from '../components/video-gallery';
import WideFAQAccordions from '../components/wide-faq-accordions';

import YoastSeoParts from '../fragments/seo';

class PageTemplate extends Component {
  
  render() {
    const page = this.props.data.wpPage;
    const { seo } = page
    const articles = this.props.data.allWpPost.nodes;
    const videoSlider = this.props.data.videoSlider.nodes;
    const videoGallery = this.props.data.videoGallery.nodes;
    const faqs = this.props.data.allWpFaq.nodes;
    const { pageAccentColor, hero, quoteSlider, copyRight, threeColumn, copyLeft, copyCenter, copyCenter2, articlesSection, solutionsSection, locationSection, copyRight2, teamMembers, cta2Column, testimonials, videoSliderSection, videoGallerySection, faqSection, wideFaqSection, form } = page.template;
    const { contactUsCopy, caHeadquarters, usHeadquarters } = this.props.data.wp.acfOptionsContact.themeOptionsContact;

    const allLocations = this.props.data.allWpLocation.nodes;
    const allTeamMembers = this.props.data.allWpLeadership.nodes;
    const allSolutions = this.props.data.allWpSolution.nodes;
  
    const allGfForm = this.props.data.allWpGfForm;
    let heroForm = "";
    let gfForm = "";

    // console.log(allGfForm);
    // console.log(form.formId);

    if(hero.heroFormId){
      allGfForm.nodes.forEach(node => {
        if (node.databaseId == hero.heroFormId)
          heroForm = node;
      });
    }
    
    if(form.formId){
      allGfForm.nodes.forEach(node => {
        if (node.databaseId == form.formId)
          gfForm = node;
      });
    }
    // console.log(gfForm);

    if(!locationSection.officeLocations)
    locationSection.officeLocations = allLocations;

    if(!solutionsSection.solutions)
    solutionsSection.solutions = allSolutions;

    let articleLink = '';
    if(articlesSection.articles && articlesSection.articles.wpURI)
    articleLink = articlesSection.articles.wpURI;

    return (
      <Layout>
        <PageWrapper accentColor={pageAccentColor?.pageAccentColor || '#21c6be'}>
          <Head title={seo.title} description={seo.metaDesc} meta={seo} />

          {/* show only if no form id in hero section */}
          {!hero?.heroFormId && (
            <ImageHero image={page.heroImage} title={hero.heroHeadline} copy={hero.heroCopy} />
          )}

          {/* TODO: */}
          {/* if there is a form id in hero section show another hero section that has a form */}

          {hero?.heroFormId && (
            <FormHero formId={hero.heroFormId} title={hero.heroHeadline} copy={hero.heroCopy} gfForm={heroForm}/>
          )}
          <div class="main-wrapper">
          <div class="main-left-wrapper">
          { !quoteSlider.hideLargeTestimonialSlider &&
            <QuoteSlider testimonials={quoteSlider.largeTestimonialSlider} />
          }

          { !copyRight.hideLeftImageRightCopy &&
            <CopyRightBlock image={copyRight.imageLeftImage} title={copyRight.imageLeftTitle} copy={copyRight.imageLeftCopy} cta={copyRight.imageLeftCta} />
          }

          { !threeColumn.hideCta3Column &&
            <ThreeColumn left={threeColumn.left3Column} middle={threeColumn.middle3Column} right={threeColumn.right3Column} />
          }

          { !copyCenter2.hideCenterCopy2 &&
            <CopyFullBlock title={copyCenter2.copyCenterTitle2} titleAlignment={copyCenter2.titleAlignment2} copy={copyCenter2.copy2Center2} cta={copyCenter2.copyCenterCta2} ctaAlt={copyCenter2.copyCenterCtaAlt2} ctaAlt3={copyCenter2.copyCenterCtaAlt3} align={copyCenter2.textAlignment2} ctaAlignment={copyCenter2.ctaAlignment2} />
          }

          { !copyLeft.hideLeftCopyRightImage &&
            <CopyLeftBlock image={copyLeft.imageRightImage} title={copyLeft.imageRightTitle} copy={copyLeft.imageRightCopy} cta={copyLeft.imageRightCta} />
          }
           { !wideFaqSection.hideWideFaq &&
            <WideFAQAccordions title={wideFaqSection.faqWideTitle} faqs={faqs} />
          }

          { !copyCenter.hideCenterCopy &&
            <CopyFullBlock title={copyCenter.copyCenterTitle} titleAlignment={copyCenter.titleAlignment} copy={copyCenter.copy2Center} cta={copyCenter.copyCenterCta} ctaAlt={copyCenter.copyCenterCtaAlt} align={copyCenter.textAlignment} ctaAlignment={copyCenter.ctaAlignment} />
          }

          { !articlesSection.hideArticles &&
            <Articles title={articlesSection.articleTitle} posts={articles} link={articleLink} />
          }

          { !solutionsSection.hideSolutions &&
            <Solutions title={solutionsSection.solutionsTitle} solutions={solutionsSection.solutions} />
          }
          { !locationSection.hideHeadquarters &&
            <Contact usHeadquarters={usHeadquarters} caHeadquarters={caHeadquarters} copy={contactUsCopy} />
          }

          { !locationSection.hideOfficeLocations &&
            <Locations title={locationSection.officeLocationsTitle} locations={locationSection.officeLocations} />
          }

          { !copyRight2.hideLeftImageRightCopy2 &&
            <CopyRightBG image={copyRight2.imageLeft2Image} title={copyRight2.imageLeft2Title} copy={copyRight2.imageLeft2Copy} cta={copyRight2.imageLeft2Cta} />
          }

          { !teamMembers.hideLeadership &&
            <TeamMembersBlock title={teamMembers.leadershipTitle} team={allTeamMembers} hideImage={this.props.pageContext.hideImages} />
          }

          { !faqSection.hideFaq &&
            <FAQAccordions title={faqSection.title} faqs={faqs} />
          }

          { !cta2Column.hideCta2Column &&
            <CTAImage leftColumn={cta2Column.left2Column} rightColumn={cta2Column.right2Column} />
          }

          { !testimonials.hideTestimonialSlider &&
            <TestimonialSlider testimonials={testimonials.testimonialSlider} />
          }

          { (!videoSliderSection.hideVideoSlider && videoSliderSection.videoSlider) &&
            <VideoSlider title={videoSliderSection.videoSlider.name} videos={videoSlider} />
          }

          { (!videoGallerySection.hideVideoGrouping && videoGallerySection.videoGrouping) &&
            <VideoGallery title={videoGallerySection.videoGrouping.name} videos={videoGallery} />
          }
          

         
          </div>
          <div class="main-right-wrapper">
            <h3>{form.formHeadline}</h3>
            <p>{form.formCopy}</p>
            
            {(!form.hideFormId && form.formId) && (
                  <GravityFormForm data={gfForm}  />
          )}
          </div>
          </div>
        </PageWrapper>
      </Layout>
    )
  }
}

const PageWrapper = styled.div`
  .defaultHero .dots span {
    color: ${({ accentColor }) => accentColor};
  }

  .defaultHero {
    border-top-color: ${({ accentColor }) => accentColor};
  }

  hr {
    border-color: ${({ accentColor }) => accentColor} !important;
  }
`

export default PageTemplate

PageTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export const query = graphql`
  query($id: String!, $articlesCategoryId: String = "//", $teamRoleId: String = "//", $faqCategoryId: String = "//", $videoSliderCategoryId: String = "//", $videoGalleryCategoryId: String = "//") {
    wpPage(id: {eq: $id}) {
      title
      uri
      id
      databaseId
      seo {
        ...seoParts
      }

      heroImage: featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }

      template {
        ... on WpTemplate_LandingPageForm {
          pageAccentColor: templateLandingPageForm {
            pageAccentColor
          }

          hero: templateLandingPageForm {
            heroHeadline
            heroCopy
            heroFormId
          }

          quoteSlider: templateLandingPageForm {
            hideLargeTestimonialSlider
            largeTestimonialSlider {
              ... on WpTestimonial {
                id
                content
                cptSingleTestimonial {
                  firstName
                  lastName
                  position
                  testimonial
                  signature {
                    localFile {
                      publicURL
                    }
                  }
                }
                featuredImage {
                  node {
                    localFile {
                      publicURL
                    }
                  }
                }
              }
            }
          }

          copyRight:templateLandingPageForm {
            hideLeftImageRightCopy
            imageLeftTitle
            imageLeftCopy
            imageLeftImage {
              altText
              localFile {
                publicURL
              }
            }
            imageLeftCta {
              target
              url
              title
            }
          }

          threeColumn: templateLandingPageForm {
            hideCta3Column
            left3Column {
              copy
              title
              image {
                altText
                localFile {
                  publicURL
                }
              }
            }
            middle3Column {
              copy
              title
              image {
                altText
                localFile {
                  publicURL
                }
              }
            }
            right3Column {
              copy
              title
              image {
                altText
                localFile {
                  publicURL
                }
              }
            }
          }

          copyLeft: templateLandingPageForm {
            hideLeftCopyRightImage
            imageRightTitle
            imageRightCopy
            imageRightTitle
            imageRightCopy
            imageRightCta {
              target
              title
              url
            }
            imageRightImage {
              altText
              localFile {
                publicURL
              }
            }
          }

          copyCenter: templateLandingPageForm {
            textAlignment
            titleAlignment
            hideCenterCopy
            copyCenterTitle
            copy2Center
            ctaAlignment
            copyCenterCta {
              target
              title
              url
            }
            copyCenterCtaAlt {
              target
              title
              url
            }
          }

          copyCenter2: templateLandingPageForm {
            textAlignment2
            titleAlignment2
            hideCenterCopy2
            copyCenterTitle2
            copy2Center2
            ctaAlignment2
            copyCenterCta2 {
              target
              title
              url
            }
            copyCenterCtaAlt2 {
              target
              title
              url
            }
            copyCenterCtaAlt3 {
              target
              title
              url
            }
          }

          articlesSection: templateLandingPageForm {
            hideArticles
            articleTitle
            articles {
              wpURI
            }
          }

          solutionsSection: templateLandingPageForm {
            hideSolutions
            solutionsTitle
            solutions {
              ... on WpSolution {
                id
                title
                link
                cptSolution {
                  icon {
                    altText
                    localFile {
                      publicURL
                    }
                  }
                  shortDescription
                }
              }
            }
          }

          locationSection: templateLandingPageForm {
            hideHeadquarters
            hideOfficeLocations
            officeLocationsTitle
            officeLocations {
              ... on WpLocation {
                id
                title
                cptLocations {
                  faxNumber
                  phoneNumber
                  address {
                    city
                    line1
                    stateProvence
                    line2
                    zipPostalCode
                  }
                }
              }
            }
          }

          copyRight2:templateLandingPageForm {
            hideLeftImageRightCopy
            imageLeftTitle
            imageLeftCopy
            imageLeftImage {
              altText
              localFile {
                publicURL
              }
            }
            imageLeftCta {
              target
              url
              title
            }
          }

          copyRight2: templateLandingPageForm {
            hideLeftImageRightCopy2
            imageLeft2Title
            imageLeft2Copy
            imageLeft2Cta {
              title
              url
              target
            }
            imageLeft2Image {
              altText
              localFile {
                publicURL
              }
            }
          }

          teamMembers: templateLandingPageForm {
            hideLeadership
            leadershipTitle
          }

          cta2Column: templateLandingPageForm {
            hideCta2Column
            left2Column {
              copy
              cta {
                target
                url
                title
              }
              image {
                altText
                localFile {
                  publicURL
                }
              }
            }
            right2Column {
              copy
              cta {
                target
                url
                title
              }
              image {
                altText
                localFile {
                  publicURL
                }
              }
            }
          }

          testimonials: templateLandingPageForm {
            hideTestimonialSlider
            testimonialSlider {
              ... on WpTestimonial {
                id
                title
                cptSingleTestimonial {
                  firstName
                  lastName
                  position
                  testimonial
                }
              }
            }
          }

          faqSection: templateLandingPageForm {
            hideFaq
            faqTitle
            faq {
              id
              name
              link
            }
          }
          wideFaqSection: templateLandingPageForm {
            hideWideFaq
            faqWideTitle
            wideFaq {
              id
              name
              link
            }
          }

          form: templateLandingPageForm {
            hideFormId
            formId
            formHeadline
            formCopy

          }

          videoSliderSection: templateLandingPageForm {
            hideVideoSlider
            videoSlider {
              name
              id
            }
          }

          videoGallerySection: templateLandingPageForm {
            hideVideoGrouping
            videoGrouping {
              name
              id
            }
          }

        }
      }
    }

    wp {
      acfOptionsContact {
        themeOptionsContact {
          contactUsCopy
          caHeadquarters {
            address
            address2
            city
            faxNumber
            mapIframe
            phoneLink
            phoneText
            postalCode
            province
          }
          usHeadquarters {
            address
            address2
            city
            faxNumber
            mapIframe
            phoneLink
            phoneText
            state
            zip
          }
        }
      }
    }

    allWpPost (
      filter: {categories: {nodes: {elemMatch: {id: {regex: $articlesCategoryId}}}}}
      sort: {order: DESC, fields: date}
      limit: 10
    ) {
      nodes {
        id
        title
        link
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              publicURL
            }
          }
        }
      }
    }

    allWpLeadership(
      filter: {teamRoles: {nodes: {elemMatch: {id: {regex: $teamRoleId}}}}}
      sort: {order: DESC, fields: date}
    ) {
      nodes {
        id
        title
        link
        content
        cptTeamMember {
          firstName
          lastName
          title
        }
        teamRoles {
          nodes {
            id
            name
            slug
          }
        }
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
      }
    }

    allWpFaq(
      filter: {faqCategories: {nodes: {elemMatch: {id: {regex: $faqCategoryId}}}}}
    ) {
      nodes {
        id
        title
        link
        excerpt
        content
        date
      }
    }

    videoSlider:allWpVideo(
      filter: {videoCategories: {nodes: {elemMatch: {id: {regex: $videoSliderCategoryId}}}}}
      limit: 10
    ) {
      nodes {
        id
        cptVideos {
          videoId
        }
      }
    }

    videoGallery:allWpVideo(
      filter: {videoCategories: {nodes: {elemMatch: {id: {regex: $videoGalleryCategoryId}}}}}
      limit: 10
    ) {
      nodes {
        id
        title
        cptVideos {
          videoId
        }
      }
    }

    allWpLocation {
      nodes {
        id
        title
        cptLocations {
          faxNumber
          phoneNumber
          address {
            city
            line1
            stateProvence
            line2
            zipPostalCode
          }
        }
      }
    }

    allWpSolution(filter: {parentId: {eq: null}}) {
      nodes {
        id
        title
        link
        cptSolution {
          icon {
            altText
            localFile {
              publicURL
            }
          }
          shortDescription
        }
        parentId
        parentDatabaseId
      }
    }
    allWpGfForm {
      nodes {
        ...GravityFormFields
      }
    }
  }
`